.cost{
    position: absolute;
    top: 29%;
    left: 0;
    width: 333px;
    height: 432px;
    right: 0;
    padding: 48px 56px;
    border-radius: 32px;
    background: linear-gradient(90.3deg, #fffcfc51 6.36%, #ffffff84 101.69%);
    z-index: 5;
    transition: 1s;
    backdrop-filter: blur(10px);
    
    
    &::before{
        content:"";
        position:absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        border-radius: 38px;
        padding:1px; 
        background:linear-gradient(90deg,#fffcfc87,#ffffff); 
        z-index: -2;
        -webkit-mask: 
           linear-gradient(#ffffff 0 0) content-box, 
           linear-gradient(#ffffff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude; 
    }
    // background-color: #7c92af;

    &_title{
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: #ffffff;
        font-size:24px;
        margin: 0 0 17px 0;
    }

    &_button{
        overflow: hidden;
        height: 255px;
        transition: 1s;
        

        &>button{
            width: 333px;
            height: 51px;
            border: 0px transparent;
            border-radius: 34px;
            margin: 17px 0;
            background: linear-gradient(91.91deg, #0524c9cc 0%, #228affcc 119.2%);
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            color: #ffffff;
            font-size:20px;
            cursor: pointer;
        }
        
        &__act{
            height: 425px;
        }
    }

    

    &_less{
        position: relative;
        display: flex;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        color: #0057B8;
        font-size: 20px;
        margin:17px 0 0 0;
        cursor: pointer;
        &>span{
            margin: 0 0 0 18px;
        }
    }
}

.cost__act{
    height: 512px;
}

#imputCost{
    width: 289px;
    height: 23px;
    border: 1px solid #0057B8;
    border-radius: 34px;
    background-color: transparent;
    outline: none;
    padding: 13px 21px;
    &::placeholder{
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 15px;
        color: #0057B84D;
        text-transform: uppercase;
    }

    &[type='number'] {
        -moz-appearance:textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    
        -webkit-appearance: none;
    }
    &[type="number"] {
        -moz-appearance: textfield;
    }
    &[type="number"]:hover,
    &[type="number"]:focus {
        -moz-appearance: number-input;
    }
}

.error{
    &::placeholder{
        color: rgb(192, 42, 42) !important;
    }
}

.arrow{
    transform: rotate(0deg);
    transition: 0.5s;
}

.arrow__act{
    transform: rotate(-90deg);
}